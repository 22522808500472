import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from '../../components/code-snippet'

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
    }

	render() {
		return (
			<CodeSnippet

			header={'Doubly Circular Linked List | Code Tutorial'}
			subHeader={''}
			description={'A no-bs line-by-line code explanation of the legendary Doubly Circular Linked List.'}
			keywords={[]}
			embeddedVideo={'KaV7BmfbhlQ'}
			embeddedCode={'https://gist.githubusercontent.com/graphoarty/d2d16938ad9709f30bdf384ad16b3ceb/raw/581753aad39429354d765764666074df0b0b2388/gistfile1.txt'}		

			></CodeSnippet>
		);
	}
}

export default View;
